@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GeneralSans';
  src: url('../lib/fonts/GeneralSans-Semibold.otf');
}

.container-xs > * {
  @apply w-full max-w-xs;
}

.container-sm > * {
  @apply w-full max-w-sm;
}

.container-md > * {
  @apply w-full max-w-md;
}

.container-lg > * {
  @apply w-full max-w-lg;
}

.container-xl > * {
  @apply w-full max-w-xl;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Show scrollbar */
  .scrollbar::-webkit-scrollbar {
    display: block;
  }
  .scrollbar {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
  }
}

@layer components {
  .no-spinner {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .masked-text {
    -webkit-text-fill-color: transparent;
  }

  .no-masked-text {
    -webkit-text-fill-color: unset;
  }
}

.font-synthesis-none {
  font-synthesis: none;
}

apple-pay-button {
  width: 100% !important;
  display: initial !important;
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 8px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

apple-pay-button[type='add-money'] {
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 12px;
  --apple-pay-button-padding: 0.5rem 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
